import * as React from "react"
import { Helmet } from "react-helmet"

import Layout from '../components/layout'

// Import from a CSS file in your src
import "../styles/lcars-classic.min.css"

export default function BeerTrek () {
  return (
  <Layout>
    <Helmet title="How to play" defer={false} />
    <div class="lcars-text-bar">
      <span>Beer-Trek - How to play</span>
    </div>
    <div style={{'color': '#ff7700'}}>
      <p>
        The rules are very simple but are very vast. There are over 300 rules and counting; we have rules for every episode and all of the movies.
        They are called rules but they operate more as guidelines: drinking this much at once may have fatal consequences and is discouraged.
      </p>
      <p>For new beer-trekkies, here's a step-by-step guide:</p>
      <ul class="lcars-list">
        <li>Step 1) Gather friends</li>
        <li>Step 2) Find beers</li>
        <li>Step 3) Watch Star Trek</li>
        <li>Step 4) Drink at appropriate times</li>
        <li>Step 5) ??????</li>
        <li>Step 6) FUN</li>
      </ul>
    </div>
  </Layout>
)}
